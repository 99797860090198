
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material-next/Button';

const API_URL = process.env.REACT_APP_API_URL || "http://165.22.32.88:5000";  // Use API from .env

export default function App() {
  const [name, setName] = useState("Felix");
  const [backendMessage, setBackendMessage] = useState(""); // New state for backend response

  useEffect(() => {
    // Fetch data from the backend when the page loads
    fetch(`${API_URL}/`)
      .then(response => response.text())
      .then(data => setBackendMessage(data))
      .catch(error => console.error("Error fetching backend:", error));
  }, []);

  return (
    <div className='App'>
      <h1>Hello {name}.</h1>
      <h2>Start editing to see some magic happen!</h2>

      <TextField 
        value={name} 
        onChange={(event) => setName(event.target.value)}
      />
      
      <Button>Food Allergies</Button>
      <Button>Diet</Button>

      {/* Display backend response */}
      <h3>Backend says: {backendMessage}</h3>  
    </div>
  );
}

// Log to console
console.log("Frontend is running...");


